import {
  AnyVaultFieldValue,
  getDefaultFieldState,
  VaultCardType,
  VaultExpiryDate,
  VaultFieldConfig,
  VaultFieldState,
  VaultFieldType,
  VaultSubmitRequest,
  VaultSubmitResponse,
} from "@april/lib-ui";
import valid from "card-validator";
import { getVaultApiHost } from "lib/env";

export const getErrorMessage = (error: any): string =>
  typeof error === "string"
    ? error
    : typeof error?.message === "string"
    ? error.message
    : typeof error?.reason === "string"
    ? error.reason
    : typeof error?.reason?.message === "string"
    ? error.reason.message
    : "Unknown error";

export const parseFormattedValue = (value: string): string =>
  value.replace(/\s/g, "");

export const parseFieldValue = (
  fieldConfig: VaultFieldConfig,
  value: string
): AnyVaultFieldValue => {
  const parsedValue = parseFormattedValue(value);

  if (fieldConfig.fieldType === "expiryDate") {
    const { year, month } = valid.expirationDate(parsedValue);

    return {
      expiryMonth: month ?? "",
      expiryYear: year ?? "",
    };
  }

  return parsedValue;
};

export const getFieldState = (
  fieldConfig: VaultFieldConfig,
  cardType: VaultCardType | null,
  value: string
): VaultFieldState => {
  const parsedValue = parseFormattedValue(value);
  const isEmpty = !parsedValue;
  const isDirty = true;

  if (fieldConfig.fieldType === "cardNumber") {
    const { card, isValid, isPotentiallyValid } = valid.number(parsedValue);
    const bin = isValid ? parsedValue.substring(0, 6) : undefined;
    const last4 = isValid
      ? parsedValue.substring(parsedValue.length - 4, parsedValue.length)
      : undefined;

    return {
      isDirty,
      isEmpty,
      isValid,
      isPossible: isPotentiallyValid,
      cardType: card?.type as VaultCardType,
      bin,
      last4,
    };
  }
  if (fieldConfig.fieldType === "expiryDate") {
    const { isValid, isPotentiallyValid } = valid.expirationDate(parsedValue);
    return {
      isDirty,
      isEmpty,
      isValid,
      isPossible: isPotentiallyValid,
    };
  }
  if (fieldConfig.fieldType === "cardCvc") {
    const maxLength = cardType === "american-express" ? 4 : 3;
    const { isValid, isPotentiallyValid } = valid.cvv(parsedValue, maxLength);
    return {
      isDirty,
      isEmpty,
      isValid,
      isPossible: isPotentiallyValid,
    };
  }

  return getDefaultFieldState();
};

export const getInputMask = (
  fieldType: VaultFieldType,
  cardType?: VaultCardType | null
): string => {
  if (fieldType === "cardNumber") {
    return cardType === "american-express"
      ? "9999 999999 99999"
      : "9999 9999 9999 9999";
  }
  if (fieldType === "expiryDate") {
    return "99 / 99";
  }
  if (fieldType === "cardCvc") {
    return cardType === "american-express" ? "9999" : "999";
  }
  return "";
};

export type VaultFieldValues = { [fieldType: string]: AnyVaultFieldValue };
export const getVaultFieldValues = (
  source: Window,
  vaultId: string
): VaultFieldValues => {
  const fieldValues: VaultFieldValues = {};

  const frames = source.frames;
  for (let i = 0; i < frames.length; i++) {
    const frame = frames[i];

    let isSameOrigin = false;
    try {
      isSameOrigin = frame.location.origin === window.location.origin;
    } catch (error) {}

    if (isSameOrigin && frame.fieldConfig?.vaultId === vaultId) {
      const input = frame.document.getElementsByTagName("input")[0];
      const value = parseFieldValue(frame.fieldConfig, input.value);
      fieldValues[frame.fieldConfig.fieldType] = value;

      if (
        frame.fieldConfig.fieldType === "expiryDate" &&
        typeof value === "object"
      ) {
        const { expiryMonth, expiryYear } = value as VaultExpiryDate;
        fieldValues.expiryMonth = expiryMonth;
        fieldValues.expiryYear = expiryYear;
      }
    }
  }

  return fieldValues;
};

export const escapeRegExp = (value: string): string =>
  value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export const submitVault = async (
  submitRequest: VaultSubmitRequest,
  fieldValues: VaultFieldValues
): Promise<VaultSubmitResponse> => {
  const body = Object.keys(fieldValues).reduce(
    (body, fieldType) =>
      body.replace(
        new RegExp(escapeRegExp(`"{{${fieldType}}}"`), "g"),
        JSON.stringify(fieldValues[fieldType])
      ),
    submitRequest.body
  );

  return fetch(`${await getVaultApiHost()}${submitRequest.path}`, {
    method: submitRequest.method,
    body,
    headers: submitRequest.headers,
  })
    .then(async (response) => {
      let body = await response.text();
      return {
        response: {
          status: response.status,
          body,
          headers: Object.fromEntries(response.headers.entries()),
        },
      };
    })
    .catch((error) => {
      return {
        error: {
          message: getErrorMessage(error),
        },
      };
    });
};
