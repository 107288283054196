export type Region = "au" | "eu";

export type Env = "dev" | "tst" | "stg" | "prd";

let _headers: Headers | null = null;
export const getHeaders = async () => {
  if (_headers) return _headers;

  const { headers } = await fetch("/");
  _headers = headers;

  return _headers;
};

export const getRegion = async () => {
  const headers = await getHeaders();

  const region = (headers.get("region") ??
    process.env.REACT_APP_REGION) as Region;

  return region;
};

export const getEnv = async () => {
  const headers = await getHeaders();

  const env = (headers.get("env") ?? process.env.REACT_APP_ENV) as Env;

  return env;
};

export const getAprilHost = async () => {
  const region = await getRegion();
  const env = await getEnv();

  if (env === "prd") return `${region}.meetapril.io`;
  if (env === "stg") return `sandbox.${region}.meetapril.io`;
  return `${env}.${region}.meetapril.dev`;
};

export const getVaultApiHost = async () =>
  `https://api-vault.${await getAprilHost()}`;

export const getVaultHost = async () =>
  window.location.href.includes("localhost")
    ? "http://localhost:3000"
    : `https://vault.${await getAprilHost()}`;
