import { VaultField } from "./components/VaultField";
import "./index.css";
import { postVaultMessage, VaultFieldErrorMessage } from "@april/lib-ui";
import { getErrorMessage } from "lib/vault";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom/client";

const errorEventListener = (event: ErrorEvent | PromiseRejectionEvent) => {
  try {
    postVaultMessage<VaultFieldErrorMessage>(window.parent, {
      type: "Error",
      errorMessage: getErrorMessage(event),
    });
  } catch (error) {}
};
window.addEventListener("error", errorEventListener);
window.addEventListener("unhandledrejection", errorEventListener);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <VaultField />
  </React.StrictMode>
);
