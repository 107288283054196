import "./VaultField.css";
import {
  postVaultMessage,
  addVaultMessageListener,
  VaultFieldConfig,
  VaultFieldState,
  VaultSubmitResponseMessage,
  VaultFieldReadyMessage,
  VaultFieldEventMessage,
  VaultCardType,
  getDefaultFieldState,
} from "@april/lib-ui";
import { ClassNames } from "@emotion/react";
import {
  getFieldState,
  getVaultFieldValues,
  getInputMask,
  submitVault,
} from "lib/vault";
import {
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEffect } from "react";
import InputMask from "react-input-mask";

export const VaultField = () => {
  const [fieldConfig, setFieldConfig] = useState<VaultFieldConfig>();
  const [fieldState, setFieldState] = useState<VaultFieldState>(
    getDefaultFieldState()
  );
  const [cardType, setCardType] = useState<VaultCardType | null>(null);
  const [inputMask, setInputMask] = useState("");
  const [value, setValue] = useState("");

  const ref = useRef({
    fieldConfig,
    cardType,
    ready: false,
    value,
  });

  useEffect(() => {
    ref.current = {
      ...ref.current,
      fieldConfig,
      cardType,
      value,
    };
  }, [fieldConfig, cardType, value]);

  useEffect(() => {
    const removeMessageListener = addVaultMessageListener(
      () => window.parent,
      (message) => {
        if (message.type === "Config") {
          window.fieldConfig = message.fieldConfig;
          setFieldConfig(message.fieldConfig);
        }

        if (message.type === "CardType") {
          setCardType(message.cardType);
        }

        if (ref.current.fieldConfig && message.type === "SubmitRequest") {
          const fieldValues = getVaultFieldValues(
            window.parent,
            ref.current.fieldConfig.vaultId
          );
          submitVault(message.submitRequest, fieldValues).then(
            (submitResponse) => {
              postVaultMessage<VaultSubmitResponseMessage>(window.parent, {
                type: "SubmitResponse",
                submitResponse,
              });
            }
          );
        }
      }
    );

    if (!ref.current.ready) {
      ref.current.ready = true;
      postVaultMessage<VaultFieldReadyMessage>(window.parent, {
        type: "Ready",
      });
    }

    return () => removeMessageListener();
  }, []);

  useEffect(() => {
    if (!fieldConfig) return;

    setInputMask(
      getInputMask(fieldConfig.fieldType, fieldState?.cardType || cardType)
    );
  }, [fieldConfig, fieldState, cardType]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!fieldConfig) return;

      const value = event.target.value;
      const fieldState = getFieldState(fieldConfig, cardType, value);

      setFieldState(fieldState);
      setValue(value);

      postVaultMessage<VaultFieldEventMessage>(window.parent, {
        type: "Event",
        eventName: "Change",
        fieldState,
      });
    },
    [fieldConfig, cardType]
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      postVaultMessage<VaultFieldEventMessage>(window.parent, {
        type: "Event",
        eventName: "Blur",
        fieldState,
      });
    },
    [fieldState]
  );

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      postVaultMessage<VaultFieldEventMessage>(window.parent, {
        type: "Event",
        eventName: "Focus",
        fieldState,
      });
    },
    [fieldState]
  );

  const classNames = useMemo(() => {
    const { isDirty, isEmpty, isValid, isPossible } = fieldState || {};

    return {
      isDirty,
      isEmpty,
      isValid,
      isPossible,
      isInvalid: isPossible === false,
    };
  }, [fieldState]);

  if (!fieldConfig) return null;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <InputMask
          type="tel"
          className={cx(css(fieldConfig.fieldStyles), classNames)}
          name={fieldConfig.fieldType}
          mask={inputMask}
          maskChar={null}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          aria-placeholder={fieldConfig.inputProps?.placeholder}
          aria-invalid={!fieldState?.isValid}
          {...(fieldConfig.inputProps as InputHTMLAttributes<HTMLInputElement>)}
        />
      )}
    </ClassNames>
  );
};
